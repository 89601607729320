.main {
  max-width: 1500px;
  margin: 0px auto;
  padding: 30px 20px 0;

  .main_aside {
    padding-bottom: 20px;

    ul {
      margin-left: 50px;
      list-style: circle;
      li {
        margin-top: 10px;
      }
    }

    .gazette_pub_img {
      width: 450px;
      height: 300px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .noLine {
    border-bottom: none;
    margin-top: 30px;
  }

  div {
    border-bottom: 1px solid #c4c4c4;

    h3 {
      color: var(--primary-color);
      margin-bottom: 10px;
    }

    aside {
      display: flex;

      span {
        font-size: 12px;
        margin-right: 30px;
      }

      section {
        div {
          height: 150px;
          background-color: #e4e4e4;
        }
      }
    }

    .image_text {
      display: flex;
      align-items: center;
      margin-bottom: 60px;

      img {
        width: 200px;
      }

      .nilds {
        // width: 100px;
        height: 70px;
      }

      .konrad {
        width: 150px;
        height: 70px;
      }
    }

    .nilds {
      display: flex;
      align-items: center;
      margin-top: 20px;

      div {
        min-width: 200px;
        display: grid;
        place-items: center;
        border-bottom: none;
        height: fit-content;
        margin: unset;
        padding: unset;

        img {
          width: 100px;
        }
      }

      span {
        display: block;
      }
    }

    .section2 {
      margin-top: 20px;
      border-bottom: none;
    }

    @media screen and (max-width: 950px) {
      aside {
        flex-direction: column-reverse;

        section {
          // margin-bottom: 20px;

          div {
            max-width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }

      .image_text {
        display: flex;
        align-items: unset;

        img {
          max-width: 100%;
          // display: none;
        }
      }
    }
  }
}
