.filters {
  background-color: #fff;
  width: 350px;
  padding: 20px 0 0;
  margin-left: 20px;
  height: fit-content;

  .toggle {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;

    h3 {
      font-size: 14px;
    }

    button {
      border: none;
      background-color: #fff;
      text-decoration: underline;
    }
  }

  .categories,
  .date,
  .keywords,
  .tags,
  .volume {
    h3 {
      font-size: 14px;
    }
    border-bottom: 1px solid var(--secondary-grey);
    padding: 0 20px;
  }

  .categories,
  .tags {
    div {
      padding: 10px 20px;

      label {
        margin-left: 10px;
        font-size: 12px;
      }
    }
  }

  .date {
    div {
      display: grid;
      padding: 10px 10px;
      grid-template-columns: repeat(3, auto);

      div {
        display: flex;
        align-items: center;
        height: 30px;

        label {
          margin-left: 5px;
          font-size: 12px;
        }
      }
    }
    padding-top: 20px;
  }

  .keywords {
    padding-top: 10px;
    padding-bottom: 15px;
    input {
      margin-left: 20px;
      padding: 5px;
      width: 80%;
      margin-top: 10px;
      border: 1px solid #c4c4c4;
    }
  }

  .tags {
    padding-top: 10px;
    border-bottom: none;

    div {
      display: flex;
      flex-wrap: wrap;

      button {
        background: none;
        background-color: #dfdfdf;
        border: none;
        width: fit-content;
        padding: 5px 10px;
        margin: 0 10px 10px 0;
        font-size: 12px;
        font-family: inherit;
      }
    }
  }

  .dateTags {
    margin-left: 20px;
    margin-bottom: 10px;
    border-bottom: none;

    div {
      display: flex;
      flex-wrap: wrap;

      button {
        background: none;
        background-color: #929292;
        border: none;
        width: fit-content;
        padding: 5px 10px;
        padding-right: 5px;
        margin: 0 10px 10px 0;
        font-size: 12px;
        font-family: inherit;

        svg {
          margin-left: 10px;
        }
      }
    }
  }

  .volume {
    padding-top: 10px;
    border-bottom: none;

    div {
      display: flex;
      flex-wrap: wrap;

      select {
        margin: 10px 0;
        padding: 5px;
        width: 100%;
        outline: none;
        font-family: inherit;
      }
    }
  }

  .filter_button {
    width: 100%;
    margin-top: 10px;

    button {
      width: 100%;
      background-color: var(--primary-color);
      color: #fff;
      padding: 10px 0;
      border: none;
      font-family: inherit;
    }
  }
}

@media screen and (max-width: 1200px) {
  .filters {
    width: 100%;
    margin-left: unset;
    margin-bottom: 10px;
    height: fit-content;

    .toggle {
      display: block;
      padding: 0px 20px;
      margin-bottom: 20px;

      button {
        padding: 10px;
        border: none;
        font-family: inherit;
        color: #fff;
      }
    }
  }
}
