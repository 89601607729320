.search {
  min-width: calc(100vw - 20px);
  height: 100px;
  
  .search_component {
    height: inherit;
    width: 1450px;
    margin: auto;
    display: flex;
    align-items: center;
    
    .search_input {
      // border: 1px solid red;
      display: flex;
      align-items: center;
      width: 1100px;
      height: 40px;
      border: 1px solid #cdcdcd;
      // padding-left: 10px;

      input {
        margin-left: 20px;
        flex-grow: 1;
        height: 35px;
        border: none;
        outline: none;
        font-size: 16px;

        &::placeholder {
          font-family: inherit;
        }
      }

      button {
        height: inherit;
        aspect-ratio: 1;
        display: grid;
        place-items: center;
        background-color: var(--secondary-grey);
        border: none;

        img {
          width: 23px;
          aspect-ratio: 1;
        }
      }

      .listening {
        background-color: var(--primary-color);
      }
    }

    .search_trigger {
      button {
        margin-left: 30px;
        font-size: 15px;
        text-decoration: underline;
        background-color: #fff;
        border: none;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .search_component {
      width: 1100px;
      .search_input {
        width: 745px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .search_component {
      // width: fit-content;
      width: 90%;

      .search_input {
        flex-grow: 1;
        width: unset;
      }
    }
  }
}
