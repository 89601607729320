.container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 10px;
  z-index: 5;
  height: 100px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "DM Sans";
  box-shadow: 1px -2px 5px 0px rgba(219, 219, 219, 0.75);
  -webkit-box-shadow: 1px -2px 5px 0px rgba(219, 219, 219, 0.75);
  -moz-box-shadow: 1px -2px 5px 0px rgba(219, 219, 219, 0.75);

  .container__text {
    display: flex;
    align-items: center;
    margin-right: 15px;

    span {
      margin-left: 20px;
      max-width: 1000px;
    }
  }

  @media screen and (max-width: 965px) {
    .container__text {
      span {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .container__text {
      span {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .container__text {
      span {
        font-size: 10px;
      }
    }
  }

  .container__btn {
    button {
      padding: 15px 25px;
      color: #fff;
      border: none;
      letter-spacing: 3px;
      background-color: var(--primary-color);
    }
  }
}
