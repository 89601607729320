.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid var(--primary-color);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 500px) {
  .loader {
    width: 30px;
    height: 30px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid var(--primary-color);
  }
}
