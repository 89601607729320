.search {
  background-color: #eeeeee;
  min-height: 100vh;

  main {
    width: 1450px;
    margin: 15px auto 0;
    display: flex;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1500px) {
  .search {
    main {
      width: 1100px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .search {
    main {
      width: unset;
      max-width: 100%;
      padding: 0 10px;
      flex-direction: column-reverse;
    }
  }
}
