.related {
  width: 350px;
  height: fit-content;
  margin-left: 30px;
  background-color: #fff;

  h1 {
    font-size: 14px;
    padding: 20px 10px;
    border-bottom: 1px solid #979797;
    font-weight: 400;
  }

  div {
    padding: 20px 20px;

    h5 {
      font-size: 12px;
      text-decoration: underline;
    }

    .abstract {
      display: block;
      font-size: 11px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .action {
      display: flex;
      padding: 0;
      margin-top: 10px;
      margin-bottom: 20px;

      button {
        height: 25px;
        font-size: 11px;
        padding: 5px 5px;
        margin-right: 10px;

        &:nth-child(1) {
          background-color: #000;
          color: #fff;
          border: none;

          a {
            color: #fff;
          }
        }

        &:nth-child(2) {
          background-color: #979797;
          color: #000;
          border: none;
        }

        &:nth-child(3) {
          background-color: #c2c2c2;
          color: #000;
          border: none;
        }
      }
    }
  }

  .load_more {
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    background-color: #cdcdcd;
    font-size: 14px;
    font-style: italic;
    color: #fff;
  }

  p {
    padding: 20px 35px;
  }
}

@media screen and (max-width: 1155px) {
  .related {
    display: none;
  }
}
