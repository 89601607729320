.share {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.583);
  display: grid;
  place-items: center;
  z-index: 2;

  .share_modal {
    width: 450px;
    height: 350px;
    background-color: #fff;
    box-shadow: 0.5px 1px 10px #d0cfcf;
    z-index: 2;

    .share_modal_header {
      background-color: #cdcdcd;
      height: 60px;
      display: grid;
      place-items: center;
      position: relative;
      margin-bottom: 30px;

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        cursor: pointer;
      }
    }

    .share_modal_input {
      padding: 0 30px;

      label {
        font-size: 12px;
        display: block;
        margin-top: 20px;
      }

      .share_modal_input_group {
        display: flex;
        border: 1px solid #eeeeee;
        margin-top: 10px;

        input {
          flex: 1;
          padding: 7px;
          border: none;
          outline: none;
        }

        div {
          width: 50px;
          display: grid;
          place-items: center;
          background-color: var(--primary-color);

          img {
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .share_modal {
      width: 90%;
    }
  }
}
