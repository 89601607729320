.signin {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.583);
  display: grid;
  place-items: center;
  z-index: 10;

  .signin_modal {
    width: 500px;
    height: 257px;
    background-color: #fff;
    box-shadow: 0.5px 1px 10px #d0cfcf;
    z-index: 2;

    .signin_modal_header {
      background-color: #cdcdcd;
      height: 60px;
      display: grid;
      place-items: center;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        cursor: pointer;
      }
    }

    p {
      margin-top: 50px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      padding: 0 20px;
    }

    .signin_modal_buttons {
      margin-top: 30px;
      display: grid;
      place-items: center;

      button {
        background-color: var(--primary-color);
        color: #fff;
        padding: 10px 15px;
        border: none;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .signin_modal {
      width: 90%;
    }
  }
}
