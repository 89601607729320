.profile {
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;

  main {
    // padding-top: 120px;
    background-color: #eeeeee;
    min-height: calc(100vh - 200px);

    .header {
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 100px;
    }

    .profile_main {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px 70px;
      // align-items: center;

      .profile_info {
        width: 505px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;

        img {
          width: 250px;
          aspect-ratio: 1;
          border: 1px solid #cccccc;
        }

        .user_box {
          width: 420px;
          margin-top: 10px;

          h4 {
            background-color: var(--primary-color);
            color: #fff;
            width: fit-content;
            padding: 5px;
            font-size: 14px;
            font-weight: 400;
            padding: 10px;
            display: grid;
            place-items: center;
          }

          .user_box_inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 30px 0px;
            font-size: 13px;

            table,
            th,
            td {
              border: 1px solid #ededed;
              border-collapse: collapse;
            }

            td {
              padding: 10px;

              &:nth-child(1) {
                width: 35%;
              }
            }
          }

          .edit_profile {
            display: flex;
            justify-content: center;

            button {
              margin-top: 30px;
              background-color: #fff;
              border: 1px solid var(--primary-color);
              padding: 10px 30px;
              font-size: 12px;
              font-family: inherit;
              color: var(--primary-color);

              &:hover {
                background-color: var(--primary-color);
                color: #fff;
              }
            }
          }
        }

        @media screen and (max-width: 1600px) {
          .user_box {
            padding: 0 0px;
          }
        }
      }

      .profile_docs {
        width: 1150px;
        margin-left: 50px;
        background-color: #fff;
        padding: 10px;

        .profile_tabs {
          display: flex;

          button {
            flex: 1;
            padding: 20px 10px;
            border: none;
            background: none;
            border-right: 1px solid #f5f5f5;
            font-family: inherit;
          }
        }

        .profile_pagination {
          height: 50px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          background-color: #f5f5f5;

          input {
            width: 400px;
            min-width: 50%;
            outline: none;
            padding: 8px 5px;
            border: 1px solid #c4c4c4;
          }

          section {
            display: flex;
            flex: 1;
            justify-content: flex-end;

            div {
              margin-left: 30px;

              span {
                font-size: 13px;
                font-weight: 400;
                margin-right: 10px;
              }

              select {
                border: none;
                font-family: inherit;
                padding: 10px;
                cursor: pointer;
              }
            }
          }
        }

        @media screen and (max-width: 1005px) {
          .profile_pagination {
            flex-direction: column;
            height: unset !important;
            align-items: unset;
            padding: 10px 10px;

            input {
              width: unset;
              min-width: 100%;
              margin-bottom: 10px;
            }
          }
        }

        .profile_table {
          border: 1px solid #fff;
          position: relative;
          min-height: 300px;
          overflow-x: scroll;

          &::-webkit-scrollbar {
            display: none;
          }

          div {
            span {
              font-size: 14px;
              font-weight: 600;

              a {
                color: var(--primary-color);
              }
            }
            margin-top: 50px;
            display: flex;
            // justify-content: center;
            height: 100%;
          }
          table {
            width: 100%;
            min-width: 850px;
            tr {
              display: block;
              display: flex;
              align-items: center;
              height: 50px;
              margin-right: 10px;

              &:nth-child(even) {
                background-color: #f5f5f5;
              }

              th {
                font-size: 14px;
                color: var(--primary-color);
              }

              td {
                font-size: 12px;

                img {
                  width: 18px;
                  margin-left: 10px;
                  cursor: pointer;
                }
              }

              th,
              td {
                &:nth-child(1) {
                  width: 5%;
                  display: flex;
                  justify-content: center;
                }

                &:nth-child(2) {
                  width: 35%;
                  display: flex;
                  justify-content: flex-start;
                  padding: 0 15px;
                }

                &:nth-child(3) {
                  width: 15%;
                  display: flex;
                  justify-content: flex-start;
                  padding: 0 15px;
                }

                &:nth-child(4) {
                  width: 20%;
                  display: flex;
                  justify-content: flex-start;
                }

                &:nth-child(5) {
                  width: 20%;
                  display: flex;
                  justify-content: flex-start;
                }
              }
            }

            // tr {
            //   height: 50px;
            //   display: flex;
            //   justify-content: space-around;
            //   align-items: center;
            //   font-size: 12px;
            //   width: 100%;
            //   color: var(--primary-color);

            //   td,
            //   th {
            //     text-align: left;
            //     border: 1px solid red;
            //   }
            // }
          }
        }

        .page_pagination {
          display: flex;
          justify-content: flex-end;
          ul {
            display: flex;
            justify-content: center;

            li {
              padding: 10px;
              cursor: pointer;

              &:hover {
                background-color: var(--primary-color);
                a {
                  color: #fff;
                }
              }
            }

            li.selected {
              border: 1px solid red;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1200px) {
      .profile_main {
        flex-direction: column;

        .profile_info {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          padding: 30px 0;

          img {
            width: 250px;
            aspect-ratio: 1;
            border: 1px solid #cccccc;
          }

          .user_box {
            width: 420px;
            margin-top: unset;

            h4 {
              background-color: var(--primary-color);
              color: #fff;
              width: fit-content;
              padding: 5px;
              font-size: 14px;
              font-weight: 400;
            }

            .user_box_inner {
              width: 100%;
              border: 1px solid #cccccc;
              display: flex;
              flex-direction: column;
              padding: 30px 20px;
              font-size: 13px;

              table,
              th,
              td {
                border: 1px solid #ededed;
                border-collapse: collapse;
              }

              td {
                padding: 10px;

                &:nth-child(1) {
                  width: 35%;
                }
              }
            }

            .edit_profile {
              display: flex;
              justify-content: flex-end;

              button {
                background-color: #222;
                border: none;
                padding: 5px 20px;
                font-size: 12px;
                font-family: inherit;
                color: #fff;
              }
            }
          }

          @media screen and (max-width: 1600px) {
            .user_box {
              padding: 0 20px;
            }
          }
        }

        .profile_docs {
          width: 100%;
          margin-left: unset;
          margin-top: 30px;
          background-color: #fff;
          padding: 10px;

          .profile_tabs {
            button {
              padding: 20px 10px;
              border: none;
              background: none;
              border-right: 1px solid #f5f5f5;
              font-family: inherit;
            }
          }

          .profile_pagination {
            height: 50px;
            background-color: #f5f5f5;
          }

          .profile_table {
            position: relative;
            div {
              span {
                font-size: 14px;
                font-weight: 600;

                a {
                  color: var(--primary-color);
                }
              }
              margin-top: 50px;
              display: flex;
              // justify-content: center;
              height: 100%;
            }
          }
        }
      }
    }

    @media screen and (max-width: 850px) {
      .profile_main {
        .profile_info {
          flex-direction: column;
          align-items: center;

          .user_box {
            width: 90%;
            margin-top: 20px;

            h4 {
              background-color: var(--primary-color);
              color: #fff;
              width: fit-content;
              padding: 5px;
              font-size: 14px;
              font-weight: 400;
            }

            .user_box_inner {
              width: 100%;
              border: 1px solid #cccccc;
              display: flex;
              flex-direction: column;
              padding: 30px 20px;
              font-size: 10px;

              table,
              th,
              td {
                border: 1px solid #ededed;
                border-collapse: collapse;
              }

              td {
                padding: 10px;

                &:nth-child(1) {
                  width: 35%;
                }
              }
            }

            .edit_profile {
              display: flex;
              justify-content: flex-end;

              button {
                background-color: #222;
                border: none;
                padding: 5px 20px;
                font-size: 12px;
                font-family: inherit;
                color: #fff;
              }
            }
          }

          @media screen and (max-width: 1600px) {
            .user_box {
              padding: 0 20px;
            }
          }
        }

        .profile_docs {
          width: 100%;
          margin-left: unset;
          margin-top: 30px;
          background-color: #fff;
          padding: 10px;

          .profile_tabs {
            button {
              padding: 20px 10px;
              border: none;
              background: none;
              border-right: 1px solid #f5f5f5;
              font-family: inherit;
            }
          }

          .profile_pagination {
            height: 50px;
            background-color: #f5f5f5;
          }

          .profile_table {
            width: 100%;
            div {
              span {
                font-size: 14px;
                font-weight: 600;

                a {
                  color: var(--primary-color);
                }
              }
              margin-top: 50px;
              display: flex;
              position: relative;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .profile {
    overflow-x: unset;
  }
}

@media screen and (max-width: 850px) {
  .profile {
    overflow-x: unset;
    main {
      overflow-x: unset;

      .profile_main {
        padding: 10px 20px;
      }
    }
  }
}
