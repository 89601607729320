.landing {
  height: calc(100vh - 370px);
  margin-top: 30px;
  display: flex;
  // align-items: center;
  padding: 0 50px;

  .map {
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    height: 100%;

    img {
      object-fit: cover;
      height: 100% !important;
    }
  }

  .graph {
    height: 150px;
    padding: 0 20px;
    display: none;
    max-width: 100vw;
    margin-bottom: 10px;

    .graph_container {
      flex-grow: 1;
      display: grid;
      grid-template-columns: repeat(24, auto);
      column-gap: 4px;

      .graph_year {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 43px;
        min-width: 43px;

        .graph_months {
          flex-grow: 1;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 2px;
          column-gap: 2px;
          position: relative;

          .month {
            border-radius: 3px;
            cursor: pointer;
            background-color: var(--primary-color);
            max-height: 20px;
          }

          .month_tooltip {
            display: none;
            position: absolute;
            top: 20px;
            width: fit-content;
            padding: 10px;
            background-color: #000;
            color: #fff;
          }

          .month:hover + .month_tooltip {
            display: block;
            z-index: 2;
          }
        }

        span {
          font-size: 11px;
        }
      }
    }

    @media screen and (max-width: 1823px) {
      .graph_container {
        overflow-x: scroll;
      }
    }
  }

  // @media screen and (max-width: 1200px) {
  //   .map {
  //     display: none !important;
  //   }

  //   // .graph {
  //   //   margin-top: 20px;
  //   //   display: flex;
  //   // }
  // }

  .recent_posts {
    max-width: 600px;
    min-width: 595px;
    height: 100%;
    background-color: #f7f7f7;
    padding: 10px 15px;
    position: relative;
    overflow-y: scroll;
    margin-left: 5px;

    &::-webkit-scrollbar {
      display: none;
    }

    h3 {
      font-weight: 600;
    }

    .posts {
      height: 100%;
      margin-top: 20px;

      .post {
        height: 30%;
        overflow-y: hidden;
        margin-top: 20px;
        margin-bottom: 5px;
        padding-bottom: 10px;
        min-height: 140px;
        border-bottom: 1px solid #505050;

        .publish_share {
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            width: 13px;
            margin-right: 20px;
            cursor: pointer;
          }
        }

        .publish_dates {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        span {
          font-size: 12px;
        }

        h4 {
          margin: 10px 0;
          font-size: 14px;
        }

        p {
          font-size: 12px;
          // display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (max-height: 890px) {
    .recent_posts {
      .post {
        height: fit-content;
      }
    }
  }

  @media screen and (max-height: 700px) {
    .recent_posts {
      .post {
        // height: 25%;
        h4 {
          margin: 5px 0;
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .landing {
    padding: 0 10px;

    // .recent_posts {
    //   max-width: 550px;
    //   min-width: 550px;
    // }
  }
}

@media screen and (max-width: 1200px) {
  .landing {
    height: unset;
    flex-direction: column;
    margin-bottom: 10px;

    .map {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;

      img {
        width: 99%;
        height: 310px;
      }
    }

    .recent_posts {
      width: 100%;
      min-width: 100%;
      min-height: 200px;
      .post {
        height: 25%;
        h4 {
          margin: 5px 0;
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .landing {
    margin-top: 5px;
  }
}
