.landing {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .column {
    display: flex;
    flex-direction: column;

    max-width: 1540px;
    margin: 0 auto;
  }

  .landing_footer {
    position: absolute;
    width: 100vw;
    bottom: 10px;
  }

  @media screen and (max-width: 1200px) {
    .landing_footer {
      position: unset;
      width: 100vw;
      bottom: 10px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .landing {
    overflow: unset;
    height: unset;
  }
}
