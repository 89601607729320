.modal {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(34, 34, 34, 0.426);
  z-index: 100;

  .modal_input {
    margin-top: 150px;
    display: grid;
    place-items: center;

    input {
      width: 70%;
      height: 50px;
      border: 1px solid var(--tertiary-grey);
      padding: 0 20px;
      outline: none;
      font-size: 16px;

      &::placeholder {
        font-size: 20px;
      }
    }

    @media screen and (max-width: 500px) {
      input {
        width: 95%;
        height: 50px;
        border: 1px solid var(--tertiary-grey);
        padding: 0 10px;
        outline: none;

        &::placeholder {
          font-size: 10px;
        }
      }
    }
  }
}
