.footer {
  height: 50px;
  padding: 0 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
  // justify-content: flex-end;

  .feedback {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #929292;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    z-index: 2;
  }

  .copy {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  div {
    display: inherit;
    align-items: center;

    span {
      font-size: 12px;
    }

    img {
      // &:nth-child(1) {
      width: 85px;
      // height: 50px;
      aspect-ratio: 1;
      object-fit: contain;
      // }
    }

    .konrad {
      width: 143px;
      // height: 100px;
      aspect-ratio: 1;
      margin-left: 10px;
      margin-right: -15px;
    }

    @media screen and (max-width: 1000px) {
      img {
        width: 55px;
        height: 30px;
      }

      .konrad {
        width: 105px;
        height: 21px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .footer {
    padding: 0 10px;
  }
}
