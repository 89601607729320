.gazette {
  width: 1100px;

  .top {
    border: 1px solid var(--primary-color);
    position: fixed;
    bottom: 100px;
    right: 100px;
    display: grid;
    place-items: center;
    padding: 10px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: var(--primary-color);

      svg {
        color: #fff !important;
      }
    }
  }

  .gazette_info {
    width: 100%;
    background-color: #fff;
    padding-bottom: 20px;
    overflow-x: hidden;
    position: sticky;
    top: 0px;
    z-index: 10;
    transition: all 0.3s ease-in-out;

    & > * {
      display: block;
    }

    h4 {
      padding: 20px 20px 5px;
      text-decoration: underline;
      font-size: 14px;
      margin-bottom: 10px;

      .gazette_date_hidden {
        display: none;
      }
    }

    .gazette_category {
      color: #979797;
      font-style: italic;
      font-size: 14px;
    }

    .gazette_date,
    .gazette_senate {
      font-size: 12px;
    }

    span {
      padding: 5px 30px;
    }

    .gazette_tags {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;

      .tags {
        span {
          &:nth-child(1) {
            font-size: 12px;
            padding: 5px 10px 5px 30px;
          }
        }

        .tag_box {
          font-size: 10px;
          background-color: #c7c7c7;
          padding: 1px 10px;
          margin-right: 10px;
        }
      }

      .action_buttons {
        display: flex;
        justify-content: center;

        button {
          margin-left: 10px;
          border: none;
          padding: 5px 5px 2px;

          img {
            width: 15px;
            aspect-ratio: 1;
          }

          &:nth-child(1) {
            background-color: var(--primary-color);
          }
          &:nth-child(2) {
            background-color: #003502;
          }
          &:nth-child(3) {
            background-color: var(--tertiary-color);
          }
        }
      }
    }

    @media screen and (max-width: 725px) {
      .gazette_tags {
        flex-direction: column;
        align-items: flex-start;

        .tags {
          display: flex;
          align-items: center;
          overflow-x: hidden;
          max-width: 100%;

          .tag_box {
            display: flex;
            height: fit-content;
            align-items: center;
          }
        }

        .action_buttons {
          margin-top: 10px;
          padding-left: 20px;
        }
      }
    }
  }

  .scroll {
    width: 100vw;
    position: fixed;
    left: 0;
    border-top: 5px solid var(--primary-color);

    h4 {
      padding: 20px 20px 15px;
      text-decoration: unset;
      // border-bottom: 1px solid #000;

      .gazette_date_hidden {
        margin-left: 10px;
        display: unset;
        padding-left: 0;
        font-weight: 400;
      }
    }

    .gazette_date,
    .gazette_senate {
      display: none;
    }

    .gazette_tags {
      flex-grow: 1;
      margin-right: 20px;

      & > div {
        margin-top: 0 !important;
      }

      .action_buttons {
        button {
          margin-left: 10px;
          border: none;
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  @media screen and (min-width: 1285px) {
    .scroll {
      display: flex;
      align-items: center;

      .gazette_tags {
        flex-grow: 1;
        margin-right: 20px;

        & > div {
          margin-top: 0 !important;
        }

        .action_buttons {
          flex-grow: 1;
          justify-content: flex-end;
        }
      }
    }
  }

  .gazette_summary {
    margin-top: 20px;
    background-color: #fff;

    h4 {
      padding: 20px 20px 10px;
      border-bottom: 1px solid #979797;
      color: #2d2d2d;
      font-size: 14px;
      font-weight: 400;
      font-style: italic;
    }

    p {
      padding: 10px 50px 30px;
      font-size: 11px;
    }
  }

  .gazette_document {
    width: 1100px;
    margin-top: 30px;
    overflow-y: scroll;
    background-color: #fff;
    position: relative;
    transition: all 0.3s ease-in-out;

    .gazette_document_tabs {
      background-color: #fafafa;
      display: flex;
      height: 55px;
      margin-bottom: 20px;
      position: sticky;
      top: 0;

      div {
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 12px;

        &:nth-child(1) {
          background-color: #fff;

          p {
            font-size: 12px;
          }
        }

        &:nth-child(2) {
          // flex: 1;
          border-left: 1px solid #c4c4c4;
          border-bottom: 1px solid #c4c4c4;

          audio {
            &::-webkit-media-controls-panel {
              background-color: #fafafa;
            }
          }
          button {
            border: none;
            background-color: #8b0000;
            color: #fff;
            font-size: 12px;
            padding: 4px 5px;

            a {
              color: #fff;
            }
          }
        }

        &:nth-child(3) {
          flex: 1;
          border-bottom: 1px solid #c4c4c4;
          justify-content: flex-end;
        }
      }
    }

    @media screen and (max-width: 1155px) {
      .gazette_document_tabs {
        .maximizeBtn {
          display: none;
        }
      }
    }

    .gazette_file {
      width: 90%;
      height: 1000px;
      margin: auto;
      border: 1px solid #c4c4c4;
      padding-bottom: 20px;

      .page_counter {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: #eeeeee;

        span {
          font-size: 12px;
        }

        button {
          background: none;
          border: none;
          margin: 0 10px;

          img {
            width: 10px;
          }
        }
      }

      .gazette_file_pdf {
        display: flex;
        justify-content: center;
        overflow-x: hidden;
        min-height: 500px;
      }
    }
  }

  .maximized {
    width: 1450px;
  }

  @media screen and (max-width: 1500px) {
    .gazette_document {
      width: 758px;
    }

    .maximized {
      width: 1100px;
    }
  }

  @media screen and (max-width: 1155px) {
    .gazette_document {
      width: 100%;
    }

    .maximized {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .gazette_document {
      display: none;
    }
  }

  .gazette_mobile_buttons {
    display: none;
  }

  @media screen and (max-width: 600px) {
    .gazette_mobile_buttons {
      display: block;
      margin-top: 20px;
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px 20px;

      button {
        padding: 10px 20px;
        border: none;
        color: #fff;
        margin-bottom: 20px;
        text-align: center;

        &:nth-child(1) {
          background-color: var(--primary-color);
        }

        &:nth-child(2) {
          background-color: #8b0000;
        }
      }

      span {
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .gazette {
    width: 758px;
  }
}

@media screen and (max-width: 1280px) {
  .gazette {
    width: 758px;
  }
}

@media screen and (max-width: 1155px) {
  .gazette {
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .gazette {
    background-color: #fff;
    width: 100%;
  }
}
