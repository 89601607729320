.graph {
  height: 145px;
  padding: 0 50px;
  display: flex;
  padding-top: 5px;
  overflow: scroll;

  .graph_container {
    display: grid;
    grid-template-columns: repeat(50, auto);
    column-gap: 4px;
    max-width: 835px;
    overflow: scroll;
    // padding: 0 10px;
    // overflow-x: scroll;
    // padding-bottom: 10px;

    // &::-webkit-scrollbar {
    //   width: 20px;
    //   height: 5px;
    // }

    // &::-webkit-scrollbar-track {
    //   display: none;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: var(--primary-color);
    //   border-radius: 10px;
    // }

    .graph_year {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 43px;
      min-width: 43px;
      position: relative;

      .graph_months {
        flex-grow: 1;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2px;
        column-gap: 2px;
        // position: relative;

        .month {
          border-radius: 3px;
          cursor: pointer;
          background-color: var(--primary-color);
          max-height: 20px;
        }

        .month_tooltip {
          display: none;
          position: absolute;
          top: -100px;
          width: fit-content;
          padding: 10px;
          background-color: #000;
          color: #fff;
        }

        .month:hover + .month_tooltip {
          display: block;
          z-index: 2;
        }
      }

      span {
        font-size: 11px;
      }
    }
  }

  @media screen and (max-width: 1823px) {
    .graph_container {
      overflow-x: scroll;
    }
  }

  .search_container {
    background-color: var(--primary-color);
    max-width: 600px;
    min-width: 600px;
    // padding: 0 15px;
    display: grid;
    grid-template-rows: repeat(3, auto);
    margin-left: 10px;

    div {
      &:nth-child(1) {
        display: flex;
        align-items: center;
        padding: 0 15px;
        h3 {
          color: #fff;
          font-size: 13px;
          font-weight: 400;
          margin-top: 5px;
        }
      }

      &:nth-child(2) {
        display: flex;
        height: 70px;
        margin-top: 10px;
        input {
          flex-grow: 1;
          outline: none;
          border: none;
          padding: 0 10px;
        }
        button {
          border: none;
          padding: 0 10px;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }

      &:nth-child(3) {
        h3 {
          visibility: HIDDEN;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .search_container {
      max-width: 100%;
      min-width: 100%;
    }
  }

  @media screen and (max-width: 820px) {
    .search_container {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

@media screen and (max-width: 1440px) {
  .graph {
    padding: 0 10px;
  }
}
