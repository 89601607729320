.navbar {
  min-width: 100vw;
  max-width: 100vw;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 20px 50px 0;
  background-color: #fff;
  border-bottom: 5px solid var(--primary-color);
  // position: fixed;
  z-index: 5;

  & > * {
    height: 100%;
    display: inherit;
    align-items: center;
  }

  .navbar_logo {
    flex-grow: 1;
    img {
      width: 250px;
    }
  }

  .navbar_links {
    ul {
      height: inherit;
      display: flex;
      align-items: center;
      width: 600px;
      max-width: 600px;
      padding: 0 15px;
      justify-content: space-between;

      li {
        height: inherit;
        font-size: 14px;
        display: flex;
        align-items: center;
        // padding: 0 25px;

        a {
          font-weight: 600;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .navbar_logo {
      img {
        width: 200px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .navbar_links {
      display: none;
    }
  }

  .navbar_search {
    padding: 0 15px;
    display: flex;
    align-items: center;
    margin-left: 50px;
    position: relative;
    height: 50px;
    background-color: var(--primary-color);

    img {
      width: 25px;
      aspect-ratio: 1;
      cursor: pointer;
    }

    .logout {
      position: absolute;
      bottom: -30px;
      left: 0;
      background-color: #fff;
      width: 100%;

      h3 {
        font-size: 14px;
        text-align: right;
        cursor: pointer;
      }
    }

    button {
      border: none;
      font-family: inherit;
      font-weight: 600;
      background: none;
      height: 50px;
      display: flex;
      align-items: center;

      &:nth-child(2) {
        display: flex;
        align-items: center;
        padding-left: 10px;
        img {
          width: 10px;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      button {
        display: none;

        img {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .navbar_search {
      margin-left: 20px;
    }
  }

  .hamburger {
    display: none;

    svg {
      font-size: 30px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1200px) {
    .hamburger {
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
  }

  .mobile_navbar {
    position: fixed;
    top: 0;
    right: -100vw;
    width: 70vw;
    height: 100vh;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    color: var(--primary-dark);
    z-index: 200;
    display: block;

    .top_container {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      flex-grow: 1;

      .hamburger svg {
        color: #e6eaf0;
      }
    }

    .bottom_container {
      margin-top: 20px;
      .menuLinks_container ul {
        li {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          padding-left: 20px;
          font-size: 13px;
          border-bottom: 1px solid #c4c4c4;
        }
      }

      .actions {
        position: absolute;
        left: 0;
        bottom: 50px;
        width: 100%;
        padding: 0 20px;

        .contact {
          margin: 0 0 25px;
          display: flex;
          justify-content: center;
        }

        .auth {
          button {
            width: 100%;
            height: 40px;
            padding: 0 24px;
            color: var(--primary-white);
            background-color: var(--primary-color);
            font-size: 16px;
            font-weight: 700;
            font-family: inherit;
            border: none;
            letter-spacing: 2px;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .mobile_navbar {
      display: none;
    }
  }

  .open {
    right: 0;
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    padding: 5px 30px 0;
    height: 70px;

    .navbar_search {
      padding: 0 10px;
      display: flex;
      align-items: center;
      position: relative;
      height: 30px;

      img {
        width: 15px;
        aspect-ratio: 1;
        cursor: pointer;
      }
    }
  }
}
