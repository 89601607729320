.feedback_container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(34, 34, 34, 0.516);
  position: fixed;
  z-index: 5;

  p {
    margin: 20px 0 50px;
    line-height: 24px;
    color: #676c8f;
    font-size: 18px;
  }

  .feedback {
    border-top: 10px solid var(--primary-color);
    background-color: #fff;
    position: absolute;
    left: 0;
    height: 100%;
    width: 600px;
    padding: 20px 20px 10px;
    font-family: "DM Sans";

    .close__feedback {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .feedback__question {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 700;
    }

    .feedback__question__question {
      display: block;
      margin-bottom: 10px;
    }

    form {
      padding: 30px 20px;
      box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.29);
      -webkit-box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.29);
      -moz-box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.29);

      label {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 400;
      }

      textarea {
        width: 100%;
        height: 150px;
        resize: none;
        border: none;
        outline: none;
        padding: 10px;
        font-family: inherit;
      }
    }

    .no__padding {
      padding: 0;
    }

    .cta {
      display: flex;
      justify-content: flex-end;
      padding: 0 0 10px;
      margin-top: 30px;

      button {
        padding: 10px 20px;
        background-color: var(--primary-color);
        color: #fff;
        border: none;
      }
    }
  }

  @media screen and (max-height: 965px) {
    .feedback {
      overflow-y: scroll;
    }
  }

  @media screen and (max-width: 600px) {
    .feedback {
      width: 100%;

      .feedback__question {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
