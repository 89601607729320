.topnav {
  width: 100vw;
  height: 35px;
  background-color: #c4c4c4;
  padding: 0 50px 0 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    font-size: 12px;
    font-weight: 600;
  }
}

@media screen and (max-width: 500px) {
  .topnav {
    padding: 5px 30px 0 0;
  }
}
