/* @media (prefers-reduced-motion: no-preference) {
 
} */

/* span {
  text-transform: uppercase; */
/* font-weight: 700;
} */

.carousel-root {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}

.carousel-slider,
.slider-wrapper,
.slider-wrapper ul,
.slider-wrapper ul li,
.slider-wrapper ul li div,
.slider-wrapper ul li div img {
  height: 100%;
}
