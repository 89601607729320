.gazette {
  background-color: #eeeeee;
  min-height: 100vh;

  main {
    width: 1450px;
    margin: 0px auto 0;
    display: flex;
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1500px) {
  .gazette {
    main {
      width: 1100px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .gazette {
    padding: 0 10px;
    main {
      width: 100%;
    }
  }
}
