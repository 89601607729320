/* Default styles to apply across the application */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@100;200;300;400&display=swap");

:root {
  --primary-color: #129017;
  --secondary-color: #540088;
  --tertiary-color: #c98500;
  --primary-grey: #424242;
  --secondary-grey: #929292;
  --tertiary-grey: #c2c2c2;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

html,
body {
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", "Poppins", "Arial", "Roboto",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

button {
  cursor: pointer;
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  font-family: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app {
  height: 100%;
}

.Toastify__toast {
  border-left: 5px solid var(--primary-color);
}

.Toastify__toast--error {
  border-left: 5px solid red;
}

.Toastify__toast-body {
  font-family: "Poppins";
}

.Toastify__toast-body > div:nth-child(2) {
  margin-left: 20px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}
