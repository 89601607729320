.faq {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 10px;

  .main {
    max-width: 1500px;
    margin: 0px auto 20px;
    padding: 50px 20px 0;
    .header {
      h3 {
        margin-bottom: 20px;
        color: var(--primary-color);
      }
    }

    .faq_desc {
      margin-bottom: 50px;
      span {
        font-size: 12px;
      }
    }

    .faq_container {
      // display: flex;
      // justify-content: space-between;
      padding-bottom: 30px;
      margin-bottom: 50px;

      .detailsDiv {
        margin-bottom: 10px;

        .detailsDivHeader {
          background-color: blue;
          border: 1px solid #e4e4e4;
          padding: 20px 20px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          background: none;
          display: flex;
          align-items: center;

          span {
            font-size: 13px;
            font-weight: 600;
            flex-grow: 1;
          }

          svg {
            cursor: pointer;
          }
        }

        .detailsDivBody {
          max-height: 0;
          background: #e9f1fe;
          overflow: hidden;

          span {
            font-size: 13px;
            flex-grow: 1;
          }
        }

        .bodyOpen {
          max-height: 1000px;
          min-height: 100px;
          padding: 10px 20px;
        }
      }

      // div {
      //   margin-right: 20px;
      //   // border-bottom: 1px solid #c4c4c4;
      //   margin-bottom: 50px;
      //   flex: 1;

      //   details {
      //     margin-bottom: 30px;
      //     cursor: pointer;

      //     summary: {
      //       border: 1px solid #e4e4e4;
      //     }

      //     span {
      //       margin-top: 10px;
      //       font-size: 12px;
      //       display: block;
      //     }
      //   }
      // }
    }

    @media screen and (max-width: 1050px) {
      .faq_container {
        flex-direction: column;
      }
    }
  }
}
